/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { useWindowDimensions } from "~hooks";
import Grid, { GRID_PADDING_PX, GRID_MAX_WIDTH_PX } from "~styles/Grid.jsx";
import * as T from "~styles/Typography.jsx";
import { LinkCard, LineSwipe, SwiperCarousel } from "~components";

import { ReactComponent as Arrow } from "~assets/svg/arrow-right.svg";
import { ReactComponent as Chevron } from "~assets/svg/chevron-right.svg";

/** ============================================================================
 * @component
 * A carousel of cards that expose text when hovered.
 */
const LinkCarouselSection = ({
  data: { headingColor, fontColor, subheading, heading, body, links }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { screen, windowSize } = useWindowDimensions();

  const [containerSize, setContainerSize] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!windowSize?.width) {
      return;
    }

    let widthDiff = 0;

    if (windowSize.width > GRID_MAX_WIDTH_PX) {
      widthDiff = parseInt((windowSize.width - GRID_MAX_WIDTH_PX) / 2);
    }

    const width = `calc(
      100% + ${widthDiff}px +
        ${GRID_PADDING_PX}px
    );`;

    setContainerSize(width);
  }, [windowSize]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          //
        `,
        tw`w-full relative pt-16 md:pt-16 lg:pt-24 pb-8 md:pb-16 lg:pb-24 flex flex-col items-center justify-center overflow-x-hidden`
      ]}
    >
      <Grid>
        <header css={[tw`col-span-12 relative flex items-center`]}>
          <T.Head _css={[tw`text-blue-light`]} font="4">
            {subheading}
          </T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe color="#84bbd9" delay={500} duration={2000} />
          </div>
        </header>

        <div
          css={[
            tw`col-span-12 md:col-span-6 relative mt-12 md:mt-12 lg:mt-20 mb-16`
          ]}
        >
          <T.Head
            _css={[
              css`
                color: ${headingColor?.hex || `#000000`};
              `,
              tw`font-bold text-blue-light`
            ]}
            font={screen === `xs` || screen === `sm` ? `1` : `2`}
          >
            {heading}
          </T.Head>
        </div>

        <div
          css={[
            css`
              color: ${fontColor?.hex || `#000000`};
            `,
            tw`col-span-12 md:col-span-4 md:col-start-1 relative mb-16 md:mb-0 md:pr-6 lg:pr-12`
          ]}
        >
          {((screen === `xs` || screen === `sm`) && (
            <T.Body font="1">{body}</T.Body>
          )) || <T.Body font="1">{body}</T.Body>}
        </div>

        <div
          css={[
            css`
              .swiper-slide {
                height: auto;
                align-self: stretch;
              }

              &:hover {
                .swiper-left,
                .swiper-right {
                  opacity: 1;
                  pointer-events: auto;
                  transform: translate3d(0, 0, 0);
                }
              }
            `,
            tw`col-span-12 md:col-span-8 relative`
          ]}
        >
          <div
            css={[
              css`
                @media screen and (min-width: 1025px) {
                  width: ${containerSize || `100%`};
                  max-width: none;
                }
              `,
              tw`relative block`
            ]}
          >
            {screen && screen !== `xs` && screen !== `sm` && (
              <SwiperCarousel
                options={{ slidesPerView: screen === `md` ? 1.75 : 2.25 }}
                nav={({ next, prev }) => (
                  <div
                    css={[
                      tw`w-full z-10 flex items-center justify-end`,
                      css`
                        position: absolute;
                        top: -5rem;
                        padding-right: 10%;
                      `
                    ]}
                  >
                    <button
                      type="button"
                      css={[tw`w-12 h-12 relative block`]}
                      onClick={() => prev()}
                    >
                      <div
                        css={[
                          css`
                            transform: scaleX(-1);

                            svg {
                              transition: 0.3s ease transform;
                            }

                            &:hover {
                              svg {
                                transform: translate3d(0.5rem, 0, 0);
                              }
                            }
                          `,
                          tw`w-full h-full relative flex items-center justify-center`
                        ]}
                      >
                        <Chevron
                          css={[
                            css`
                              height: 60%;
                              pointer-events: none;
                            `,
                            tw``
                          ]}
                        />
                      </div>
                    </button>

                    <button
                      type="button"
                      css={[tw`w-12 h-12 relative block`]}
                      onClick={() => next()}
                    >
                      <div
                        css={[
                          css`
                            svg {
                              transition: 0.3s ease transform;
                            }

                            &:hover {
                              svg {
                                transform: translate3d(0.5rem, 0, 0);
                              }
                            }
                          `,
                          tw`w-full h-full relative flex items-center justify-center`
                        ]}
                      >
                        <Chevron
                          css={[
                            css`
                              height: 60%;
                              pointer-events: none;
                            `,
                            tw``
                          ]}
                        />
                      </div>
                    </button>
                  </div>
                )}
                slides={links.map((link) => (
                  <LinkCard data={link} />
                ))}
              />
            )}

            {((screen && screen === `xs`) || screen === `sm`) && (
              <>
                {links.map((link) => (
                  <LinkCard data={link} />
                ))}
              </>
            )}
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default LinkCarouselSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment LinkCarouselSectionFragment on SanityLinkCarouselSection {
    _type

    subheading
    heading
    body

    headingColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    links {
      link
      subheading
      heading
      body

      image {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
        }
      }
    }
  }
`;
